import Api from './Api';

export interface UserProfileData {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    is_superuser: boolean;
    role: string;
}

class UserClient {
    async fetchUserProfile(): Promise<{ status?: string; data?: UserProfileData[] }> {
        try {
            const response = await Api.request('/api/userprofile', {
                method: 'GET',
            });
            if (response && response.data) {
                response.data.forEach((user: UserProfileData) => { 
                    user.role = user.is_superuser ? 'Administrator' : 'User'; 
                });
            }
            return response;
        } catch (error) {
            console.error('Error fetching user profiles:', error);
            return { status: 'fail' };
        }
    }

    async deleteUser(id: string): Promise<{ status?: string; error?: string }> {
        try {
            const response = await Api.request(`/api/userprofile/${id}`, {
                method: 'DELETE',
            });
            return response; 
        } catch (error) {
            console.error('Error deleting user:', error);
            return { status: 'fail' };
        }
    }
}

export const userClient = new UserClient();
