import Api from './Api'

export interface SignUpParams {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  /*componentId: string;
  parameters: {
    [key: string]: string[] | string | null; 
    };
  componentId2: string;
  parameters2: {
    [key: string]: string[] | string | null; 
    };
  componentId3: string;
  parameters3: {
    [key: string]: string[] | string | null; 
    };*/
}
export interface SigninParams {
  email: string;
  password: string;
}

//class that includes all APIs related to user authorization
class AuthClient {
  // Use the POST method to send a request to the signup endpoint
  async signUp(params: SignUpParams): Promise<{ status?: string; error?: string }> {
    try {
      const response = await Api.request('/api/signup/', {
        method: 'POST',
        data: params, 
      });
      return response;
    } catch (error) {
          console.error('Error sign up:', error);
          return { status: 'fail' };
    }
  }
  // Use the POST method to send a request to the signin endpoint
  async signIn(params: SigninParams): Promise<{ status?: string; error?: string }> {
    try {
      const response = await Api.request('/api/login/', {
        method: 'POST',
        data: params, 
      })
      return response;
    } catch (error) {
          console.error('Error sign in:', error);
          return { status: 'fail' };
    }
  }

  // Use the POST method to send a request to the logout endpoint
  async logout(): Promise<{ status?: string; error?: string }> {
    try {
      const response = await Api.request('/api/logout/', {
        method: 'POST',
      });
      return response;
    } catch (error) {
          console.error('Error log out:', error);
          return { status: 'fail' }; 
    }
  }

    async checkSessionKey(): Promise<{ authenticated?: boolean; status?: string; error?: string }> {
    try {
      const response = await Api.request('/api/session-check/', {
        method: 'GET',
        credentials: 'include',  // Include cookies in the request
      });
        console.log("response: ", response);
        return response;
    } catch (error) {
          console.error('Error checking session:', error);
          return { status: 'fail' };
    }
  }
}

export const authClient = new AuthClient();