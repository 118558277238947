import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

interface CustomizationComponentProps {
    onComponentChange: (
        event: SelectChangeEvent<string>,
        parameters: {
            [key: string]: string[] | string | null;
        }
    ) => void;
}

const Edit: React.FC<CustomizationComponentProps> = ({ onComponentChange }) => {
    const [open, setOpen] = useState(false);
    const [dialogStep, setDialogStep] = useState(1);
    const [selectedParameterType, setSelectedParameterType] = useState<string>('');
    const [selectedPonds, setSelectedPonds] = useState<string[]>([]);
    const [selectedChart, setSelectedChart] = useState<string>('');
    const [selectedStartDate, setStartDate] = useState<Dayjs | null>(dayjs());
    const [selectedEndDate, setEndDate] = useState<Dayjs | null>(dayjs());

    const handleOpen = () => {
        setOpen(true);
    };

    const handleParameterTypeChange = (event: SelectChangeEvent<string>) => {
        setSelectedParameterType(event.target.value);
    };

    const handlePondChange = (event: SelectChangeEvent<typeof selectedPonds>) => {
        const value = event.target.value as string[];
        setSelectedPonds(value);
    };

    const handleChartChange = (event: SelectChangeEvent<string>) => {
        setSelectedChart(event.target.value);
    };

    const handleStartDateChange = (date: Dayjs | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Dayjs | null) => {
        setEndDate(date);
    };

    const handleClose = () => {
        setOpen(false);
        setDialogStep(1);
        resetSelections();
    };

    const resetSelections = () => {
        setSelectedParameterType('');
        setSelectedPonds([]);
        setSelectedChart('');
        setStartDate(null);
        setEndDate(null);
    };

    const handleNext = () => {
        setDialogStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setDialogStep((prevStep) => prevStep - 1);

        // Reset parameters based on the current dialog step
        switch (dialogStep) {
            case 2:
                // Reset selected ponds
                setSelectedPonds([]);
                break;
            case 3:
                // Reset selected chart or selected start & end dates
                setSelectedChart('');
                break;
            case 4:
                // Reset selected start & end dates
                setStartDate(null);
                setEndDate(null);
                break;
            // Add cases for additional dialog steps if needed
            default:
                break;
        }

    };

    const handleOk = () => {
        const parameters = {
            Chart: selectedChart || '',
            Pond: selectedPonds,
            StartDate: selectedStartDate ? selectedStartDate.format('DD-MM-YYYY') : '',
            EndDate: selectedEndDate ? selectedEndDate.format('DD-MM-YYYY') : '',
        };

        const event = {
            target: {
                value: selectedParameterType,
                name: 'component-select',
            },
        };

        onComponentChange(event as SelectChangeEvent<string>, parameters);
        handleClose();
    };

    const renderCommonDialogs = () => (
        <>
            {dialogStep === 2 && (
                <>
                    <DialogTitle>Select Ponds</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="pond-select">Ponds</InputLabel>
                            <Select
                                labelId="pond-select-label"
                                id="pond-select"
                                multiple={selectedParameterType !== 'Physical'}
                                value={selectedPonds}
                                onChange={handlePondChange}
                                input={<OutlinedInput label="Ponds" />}
                                renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : '')}
                            >
                                {['G2', 'G3', 'G4', 'G5', 'G7', 'G8', 'G9', 'G10'].map((pond) => (
                                    <MenuItem key={pond} value={pond}>
                                        <Checkbox checked={selectedPonds.indexOf(pond) > -1} />
                                        <ListItemText primary={pond} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleNext} disabled={selectedPonds.length === 0}>Next</Button>
                    </DialogActions>
                </>
            )}
            {dialogStep === 3 && (
                <>
                    <DialogTitle>Select Chart Type</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="chart-select">Chart Type</InputLabel>
                            <Select
                                labelId="chart-select-label"
                                id="chart-select"
                                value={selectedChart}
                                onChange={handleChartChange}
                                input={<OutlinedInput label="Chart Type" />}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {selectedParameterType === 'Biology' && [
                                    <MenuItem key="Pie Chart" value="Pie Chart">Pie Chart</MenuItem>,
                                    <MenuItem key="Bar Graph" value="Bar Graph">Bar Graph</MenuItem>,
                                    selectedPonds.length === 1 && <MenuItem key="Line Graph" value="Line Graph">Line Graph</MenuItem>
                                ]}
                                {selectedParameterType === 'Chemical' && [
                                    <MenuItem key="Bar Graph" value="Bar Graph">Bar Graph</MenuItem>,
                                    selectedPonds.length === 1 && <MenuItem key="Line Graph" value="Line Graph">Line Graph</MenuItem>
                                ]}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleNext} disabled={!selectedChart}>Next</Button>
                    </DialogActions>
                </>
            )}
            {dialogStep === 4 && selectedChart === 'Line Graph' && (
                <>
                    <DialogTitle>Select Date Range</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        value={selectedStartDate}
                                        onChange={handleStartDateChange}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        value={selectedEndDate}
                                        onChange={handleEndDateChange}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleNext} disabled={!selectedStartDate || !selectedEndDate}>Next</Button>
                    </DialogActions>
                </>
            )}
            {dialogStep === 4 && selectedChart !== 'Line Graph' &&(
                <>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        Are you sure you want to proceed with the selected options?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleOk}>OK</Button>
                    </DialogActions>
                </>
            )}
            {dialogStep === 5 && selectedChart === 'Line Graph' && (
                <>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        Are you sure you want to proceed with the selected options?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleOk}>OK</Button>
                    </DialogActions>
                </>
            )}
        </>
    );

    const renderPhysicalDialog = () => (
        <>
            {dialogStep === 2 && (
                <>
                    <DialogTitle>Select Pond</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="pond-select">Pond</InputLabel>
                            <Select
                                labelId="pond-select-label"
                                id="pond-select"
                                value={selectedPonds.length > 0 ? selectedPonds[0] : ''}
                                onChange={(event) => setSelectedPonds([event.target.value])}
                                input={<OutlinedInput label="Pond" />}
                            >
                                {['G2', 'G3', 'G4', 'G5', 'G7', 'G8', 'G9', 'G10'].map((pond) => (
                                    <MenuItem key={pond} value={pond}>
                                        {pond}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleNext} disabled={selectedPonds.length === 0}>Next</Button>
                    </DialogActions>
                </>
            )}
            {dialogStep === 3 && (
                <>
                    <DialogTitle>Select Chart Type</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="chart-select">Chart Type</InputLabel>
                            <Select
                                labelId="chart-select-label"
                                id="chart-select"
                                value={selectedChart}
                                onChange={handleChartChange}
                                input={<OutlinedInput label="Chart Type" />}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {selectedParameterType === 'Physical' && [   
                                    selectedPonds.length === 1 && <MenuItem key="Line Graph" value="Line Graph">Line Graph</MenuItem>
                                ]}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleNext} disabled={!selectedChart}>Next</Button>
                    </DialogActions>
                </>
            )}
            {dialogStep === 4 && (
                <>
                    <DialogTitle>Select Date Range</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Start Date"
                                        value={selectedStartDate}
                                        onChange={handleStartDateChange}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="End Date"
                                        value={selectedEndDate}
                                        onChange={handleEndDateChange}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleNext} disabled={(!selectedStartDate) && (!selectedEndDate)}>Next</Button>
                    </DialogActions>
                </>
            )}
            {dialogStep === 5 && (
                <>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        Are you sure you want to proceed with the selected options?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack}>Back</Button>
                        <Button onClick={handleOk}>OK</Button>
                    </DialogActions>
                </>
            )}
        </>
    );

    return (
        <div>
            <Box boxShadow={3} borderRadius={4}>
                <Button onClick={handleOpen} variant="contained">Add</Button>
            </Box>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                {dialogStep === 1 && (
                    <>
                        <DialogTitle>Select Parameter Type</DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="parameter-type-select">Parameter Type</InputLabel>
                                <Select
                                    labelId="parameter-type-select-label"
                                    id="parameter-type-select"
                                    value={selectedParameterType}
                                    onChange={handleParameterTypeChange}
                                    input={<OutlinedInput label="Parameter Type" />}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="Biology">Biology</MenuItem>
                                    <MenuItem value="Chemical">Chemical</MenuItem>
                                    <MenuItem value="Physical">Physical</MenuItem>
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleNext} disabled={!selectedParameterType}>Next</Button>
                        </DialogActions>
                    </>
                )}
                {selectedParameterType === 'Physical' ? renderPhysicalDialog() : renderCommonDialogs()} {/* Common Dialog refers to Biology & Chemical */}
            </Dialog>
        </div>
    );
};

export default Edit;
