import { redirect } from 'react-router-dom';

interface ApiInit extends RequestInit {
    params?: Record<string, string>
    data?: Record<string, any>
}
interface Api {
    request: (endpoint: string, settings: ApiInit) => Record<string, any>
}

interface ApiResponse{
    readonly need_redirect?: boolean;
    readonly redirect_url?: string;
}

const baseUrl = process.env.REACT_APP_API_URL

export const parseJson = (response: Response): Record<string, any> =>
    response.json()

export const parseUrl = (
    url: string,
    params?: Record<string, string>
): string => {
    const baseUrl = process.env.REACT_APP_API_URL
    const querystring = params
        ? `?${new URLSearchParams(params).toString()}`
        : ''
    return `${baseUrl}${url}${querystring}`
}

export const prepareRequestSignature = ({
    method,
    data,
}: {
    method?: string
    data?: Record<string, any>
}): Record<string, any> => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }

    return {
        body: data ? JSON.stringify(data) : undefined,
        method,
        headers,
        credentials: 'include'
    }
}

// check the status of api response HQ: Need to add and modify new response status to handle properly
export const checkStatus = async (response: Response): Promise<ApiResponse> => {
    const data: ApiResponse = await response.json()
    console.log('data.next:',window.location.pathname)
    if (response.ok) {
        return data
    }
    if(response.status===401){
        if (data.need_redirect && data.redirect_url) {
            window.location.replace(`${encodeURIComponent(data.redirect_url)}?next=${window.location.pathname}`)
            throw new Error('Redirect triggered')
          }
        throw new Error(`${response.status} ${response.statusText}`)
    }//else{
     //   window.location.replace(`${baseUrl}/error/?status=${encodeURIComponent(response.status)}&statusText=${encodeURIComponent(response.statusText)}`);
    //}
    throw new Error(`${response.status} ${response.statusText}`)
}

const apiImpl = {
    request: (
        endpoint: string,
        { params, method, data }: ApiInit
    ): Record<string, any> =>
        fetch(
            parseUrl(endpoint, params),
            prepareRequestSignature({ method, data })
        )
            .then(checkStatus)

}

export default apiImpl as Api
