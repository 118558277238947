import { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { authClient } from '../APIClient/AuthClient';

// Check if there is a session key in cookie

interface RouteProps {
    children: ReactNode
}

// Route wrapper for authentication routes, redirects authenticated users to the homepage
export const AuthRoute: React.FC<RouteProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await authClient.checkSessionKey();
                const isAuthenticated = response.authenticated ?? false; // Provide a fallback value
                console.log('isAuthenticated?:', isAuthenticated);
                setIsAuthenticated(isAuthenticated);
            } catch (error) {
                console.error('Error checking session:', error);
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? <Navigate to="/overview" replace /> : <>{children}</>;
};

// Route wrapper for private routes, redirects unauthenticated users to the sign-in page
export const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await authClient.checkSessionKey();
                const isAuthenticated = response.authenticated ?? false; // Provide a fallback value
                console.log('isAuthenticated?:', isAuthenticated);
                setIsAuthenticated(isAuthenticated);
            } catch (error) {
                console.error('Error checking session:', error);
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }
    return isAuthenticated ? children : <Navigate to="/auth/signin" replace />
};