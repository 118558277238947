import { Box, Stack, Typography } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';

export default function Auth() {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    // if the url is exactly '/auth', redirect to '/auth/signin'
    if (location.pathname === '/auth') {
      navigate('/auth/signin', { replace: true });
    }
  }, [location, navigate]);

  return (
      <Box component={'main'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', backgroundColor: '#F5F5F5' }}>
          <Box className="container" sx={{ display: 'flex', width: '1000px', height: '600px', backgroundColor: 'white', borderRadius: '10px', boxShadow: '5px 5px 10px 0 rgba(0, 0, 0, 0.5)' }}>
              <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{
                      width: '50%',
                      backgroundColor: '#7375F9',
                      borderRadius: '10px 0 0 10px',
                      padding: 5,
                      backgroundImage: 'url(/images/three-falling-water-drops-trout55.jpg)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      position: 'relative',
                      color: 'white'
                  }}
              >
                  <Box
                      sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',  
                          borderRadius: '10px 0 0 10px'
                      }}
                  />
                  <Typography
                      variant="h3"
                      component="h1"
                      align='left'
                      sx={{
                          position: 'relative',
                          fontWeight: 'bold',
                          width: '100%',
                          maxWidth: '400px',
                          zIndex: 1,
                          top: '-50px'  // Shift text up
                      }}
                  >
                      AquaCulture
                  </Typography>
                  <Typography
                      variant="body1"
                      sx={{
                          position: 'relative',
                          maxWidth: '400px',
                          zIndex: 1,
                          top: '-50px'  // Shift text up
                      }}
                  >
                      An advanced water quality management platform to provide early warning, risk assessment and source tracking of hazardous substances.
                  </Typography>
              </Stack>
              <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: '50%', borderRadius: '0 10px 10px 0', padding: 4 }}
              >
                  <Outlet />
              </Stack>
          </Box>
      </Box>
  )

}