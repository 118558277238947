import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface PredictionParameterData {
    [parameter: string]: number | null;
    predicted_E_coli_concentration: number | null;
    actual_E_coli_concentration: number | null;
    predicted_Enterococcus_concentration: number | null;
    actual_Enterococcus_concentration: number | null;
}

interface PredictionDateData {
    [date: string]: PredictionParameterData[];
}

interface PredictionAggregateData {
    [pond: string]: PredictionDateData;
}

interface LineGraphProps {
    data: PredictionAggregateData;
    componentId: string;
    showDeleteIcon: boolean;
    handleDelete: (componentId: string) => void;
}

const PredictionLineGraph = ({ data, componentId, showDeleteIcon, handleDelete }: LineGraphProps) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);

    let title = '';
    let relevantParameters: string[] = [];

    if (componentId.includes('Ecoli')) {
        title = 'E Coli Prediction';
        relevantParameters = ['predicted_E_coli_concentration', 'actual_E_coli_concentration'];
    }

    if (componentId.includes('Enterococcus')) {
        title = 'Enterococcus Prediction';
        relevantParameters = ['predicted_Enterococcus_concentration', 'actual_Enterococcus_concentration'];
    }

    useEffect(() => {
        if (chartContainerRef.current) {
            const myChart = echarts.init(chartContainerRef.current);

            const ponds = Object.keys(data);
            const dates = Array.from(new Set(ponds.flatMap(pond => Object.keys(data[pond])))).sort();

            const seriesData = relevantParameters
                .flatMap(parameter => 
                    ponds.flatMap(pond => {
                        const pondSeries = dates.map(date => {
                            const entry = data[pond][date]?.[0];
                            return entry && entry[parameter] !== null ? entry[parameter] : '-';
                        });

                        if (pondSeries.some(value => value !== '-')) {
                            return [{
                                name: `${parameter} (${pond})`,
                                type: 'line',
                                data: pondSeries,
                            }];
                        }
                        return [];
                    })
                );

            const option = {
                title: {
                    text: `${title} (G2 and G3)`,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    bottom: '0',
                    left: 'center',
                    data: seriesData.map(series => series.name)
                },
                xAxis: {
                    type: 'category',
                    data: dates,
                    boundaryGap: false
                },
                yAxis: {
                    type: 'value'
                },
                series: seriesData
            };

            myChart.setOption(option);

            return () => {
                myChart.dispose();
            };
        }
    }, [data]);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                bgcolor: 'white',
                width: '100%',
                height: '300px', 
                padding: '1px',
                aspectRatio: '2',
            }}
        >
            <div ref={chartContainerRef} style={{ height: '100%', width: '100%' }}></div>
            {showDeleteIcon && (
                <IconButton
                    style={{
                        position: 'absolute',
                        bottom: '4px',
                        right: '4px',
                        color: 'red',
                    }}
                    onClick={() => handleDelete(componentId)}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default PredictionLineGraph;