import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../DashboardUI/LayoutComponent';
import LineGraph from './LineGraph';

interface SensorData {
    [timestamp: string]: {
        temperature: number;
        pH: number;
        salinity: number;
        DO: number;
    };
}

interface PondData {
    G2: SensorData;
    G3: SensorData;
    G4: SensorData;
}

const Realtime: React.FC = () => {
    const [sensorData, setSensorData] = useState<PondData>({ G2: {}, G3: {}, G4: {} });

    const wsRefs: { [key in keyof PondData]: React.MutableRefObject<WebSocket | null> } = {
        G2: useRef<WebSocket | null>(null),
        G3: useRef<WebSocket | null>(null),
        G4: useRef<WebSocket | null>(null),
    };

    const sensorEndpoints = {
        G2: `${process.env.REACT_APP_API_URL}/ws/pondg2_physical_endpoint/`,
        G3: `${process.env.REACT_APP_API_URL}/ws/pondg3_physical_endpoint/`,
        G4: `${process.env.REACT_APP_API_URL}/ws/pondg4_physical_endpoint/`
    };

    useEffect(() => {
        // Function to establish WebSocket connection
        const connectWebSocket = (pond: keyof PondData) => {
            const endpoint = sensorEndpoints[pond];
            const ws = new WebSocket(endpoint);

            ws.onopen = () => {
                console.log(`${pond} WebSocket connection established`);
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    const timestamp = new Date(data.timestamp).toLocaleString();

                    setSensorData(prevData => ({
                        ...prevData,
                        [pond]: {
                            ...prevData[pond],
                            [timestamp]: {
                                temperature: data.temperature,
                                pH: data.pH,
                                salinity: data.salinity,
                                DO: data.DO,
                            }
                        }
                    }));
                } catch (error) {
                    console.error(`Error parsing ${pond} WebSocket message:`, error);
                }
            };

            ws.onerror = (error) => {
                console.error(`${pond} WebSocket error:`, error);
            };

            ws.onclose = (event) => {
                console.log(`${pond} WebSocket connection closed`, event);
                // Attempt to reconnect on close
                setTimeout(() => {
                    connectWebSocket(pond);
                }, 3000); // Retry every 3 seconds (adjust as needed)
            };

            // Save WebSocket instance to ref
            wsRefs[pond].current = ws;
        };

        // Connect WebSocket for each pond
        Object.keys(wsRefs).forEach((pondKey) => {
            const pond = pondKey as keyof PondData;
            connectWebSocket(pond);
        });

        // Clean up function
        return () => {
            Object.keys(wsRefs).forEach((pondKey) => {
                const pond = pondKey as keyof PondData;
                if (wsRefs[pond].current) {
                    wsRefs[pond].current!.close();
                }
            });
        };
    }, []); // Empty dependency array ensures this effect runs only once

    return (
        <Layout>
            <div style={{ textAlign: 'left', margin:'auto' }}>
                <h5>Data is populating at real-time every minute, please wait for a moment.</h5>
            </div>
            <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr', height: '100vh' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                    <LineGraph data={sensorData} parameter="temperature" title="Temperature" />
                    <LineGraph data={sensorData} parameter="DO" title="Dissolved Oxygen" />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                    <LineGraph data={sensorData} parameter="salinity" title="Salinity" />
                    <LineGraph data={sensorData} parameter="pH" title="pH" />
                </div>
            </div>
        </Layout>
    );
}

export default Realtime;