import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the DeleteIcon
interface AggregateData {
    [group: string]: GroupData;
}

interface GroupData {
    [item: string]: number;
}

interface PieChartProps {
    /** Data for the pie chart */
    data: AggregateData;
    componentId: string;
    showDeleteIcon: boolean; // Prop to control delete icon visibility
    handleDelete: (componentId: string) => void; // Function to handle deletion
}

const PieChart = ({ data, componentId, showDeleteIcon, handleDelete }: PieChartProps) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (chartContainerRef.current) {
            const myChart = echarts.init(chartContainerRef.current);

            const groupsInvolved = Object.keys(data).join(', ');

            let title = '';

            if (componentId.includes('Biology')) {
                title = 'Pathogen Distribution Chart';
            }
            if (componentId.includes('Chemical')) {
                title = 'Chemical Distribution Chart';
            }

            const aggregatedData: { [item: string]: number } = {};

            // Aggregate the values of the same item across different ponds
            Object.values(data).forEach((pondData) => {
                Object.entries(pondData).forEach(([item, value]) => {
                    aggregatedData[item] = (aggregatedData[item] || 0) + value;
                });
            });

            const option = {
                title: {
                    text: `${title} (${groupsInvolved})`, // Include all names in the title
                    left: 'center',
                    textStyle: {
                        fontSize: 15 
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)',
                },
                legend: {
                    bottom: -5,
                    left: 'center',
                    data: Object.keys(aggregatedData), // Use aggregated item names as legend data
                    textStyle: {
                        fontSize: 8
                    }
                },
                series: [
                    {
                        name: 'Data',
                        type: 'pie',
                        radius: '65%',
                        center: ['50%', '50%'],
                        selectedMode: 'single',
                        data: Object.entries(aggregatedData).map(([item, value]) => ({
                            name: item,
                            value,
                        })),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            };

            myChart.setOption(option);

            // Clean up function to dispose the chart when component unmounts
            return () => {
                myChart.dispose();
            };
        }
    }, [data]);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                bgcolor: 'white',
                p: 2,
                width: '100%',
                height: '520px',
                aspectRatio: '1', // Maintain a square aspect ratio
            }}
        >
            <div ref={chartContainerRef} style={{ height: 'calc(100% - 40px)', width: '100%' }}></div>
            {/* Render the delete icon conditionally */}
            {showDeleteIcon && (
                <IconButton
                    style={{
                        position: 'absolute',
                        bottom: '4px',
                        right: '4px',
                        color: 'red', 
                    }}
                    onClick={() => handleDelete(componentId)}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default PieChart;
