import React, { useEffect, useState } from 'react';
import { dataClient } from '../../APIClient/DataClient'; 
import LineGraph from './HistoricalLineGraph'; 
import moment from 'moment';
import Layout from '../LayoutComponent';
import PredictionLineGraph from './PredictionLineGraph';
import Box from '@mui/material/Box';
import { Padding } from '@mui/icons-material';

interface PondDataEntry {
    timestamp: string;
    temperature: number;
    pH: number;
    salinity: number;
    DO: number;
}

interface ParameterData {
    [parameter: string]: number | null;
    temperature: number | null;
    pH: number | null;
    salinity: number | null;
    DO: number | null;
}

interface DateData {
    [date: string]: ParameterData[];
}

interface AggregateData {
    [pond: string]: DateData;
}

// Ecoli Prediction
interface PredictionPondDataEntry {
    timestamp: string;
    predicted_E_coli_concentration: number;
    actual_E_coli_concentration: number;
    predicted_Enterococcus_concentration: number;
    actual_Enterococcus_concentration: number;
}

interface PredictionParameterData {
    [parameter: string]: number | null;
    predicted_E_coli_concentration: number | null;
    actual_E_coli_concentration: number | null;
    predicted_Enterococcus_concentration: number | null;
    actual_Enterococcus_concentration: number | null;
}

interface PredictionDateData {
    [date: string]: PredictionParameterData[];
}

interface PredictionAggregateData {
    [pond: string]: PredictionDateData;
}

const HistoricalDataPage = () => {
    const [data, setData] = useState<AggregateData | null>(null);
    const [eColiPredictionData, setEColiPredictionData] = useState<PredictionAggregateData | null>(null);
    const [enterococcusPredictionData, setEnterococcusPredictionData] = useState<PredictionAggregateData | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const now = moment.utc(); // Use UTC time
            const endDate = now.format('DD-MM-YYYY HH:mm'); // Adjust to UTC
            const startDate = now.clone().subtract(10, 'minutes').format('DD-MM-YYYY HH:mm'); // Adjust to UTC

            const fetchPondData = async (pondType: string) => {
                const response = await dataClient.fetchPhysicalPondData({ pondType, startDate, endDate });
                if (response.status === 'success' && response.data) {
                    return formatData(response.data, pondType);
                }
                return null;
            };

            const fetchPredictionData = async (pondType: string, pathogenType: string) => {
                const response = await dataClient.fetchHistoricalPredictedPondData({ pondType, pathogenType, startDate, endDate });
                if (response.status === 'success' && response.data) {
                    return formatPredictionData(response.data, pondType);
                }
                return null;
            };

            const g2Data = await fetchPondData('G2');
            const g3Data = await fetchPondData('G3');
            setData({ ...g2Data, ...g3Data });

            const g2EcoliPredictionData = await fetchPredictionData('G2', 'Ecoli');
            const g3EcoliPredictionData = await fetchPredictionData('G3', 'Ecoli');
            setEColiPredictionData({ ...g2EcoliPredictionData, ...g3EcoliPredictionData });

            const g2EnterococcusPredictionData = await fetchPredictionData('G2', 'Enterococcus');
            const g3EnterococcusPredictionData = await fetchPredictionData('G3', 'Enterococcus');
            setEnterococcusPredictionData({ ...g2EnterococcusPredictionData, ...g3EnterococcusPredictionData });
        };

        fetchData();
        const interval = setInterval(fetchData, 60000); // Refresh every minute

        return () => clearInterval(interval);
    }, []);

    const formatData = (rawData: PondDataEntry[], pondType: string): AggregateData => {
        const formatted: AggregateData = { [pondType]: {} };

        rawData.forEach(entry => {
            const date = entry.timestamp.slice(0, 16); // Strip seconds for consistent x-axis
            if (!formatted[pondType][date]) {
                formatted[pondType][date] = [];
            }
            formatted[pondType][date].push({
                temperature: entry.temperature,
                pH: entry.pH,
                salinity: entry.salinity,
                DO: entry.DO,
            });
        });

        return formatted;
    };

    const formatPredictionData = (rawData: PredictionPondDataEntry[], pondType: string): PredictionAggregateData => {
        const formatted: PredictionAggregateData = { [pondType]: {} };

        rawData.forEach(entry => {
            const date = entry.timestamp.slice(0, 16); // Strip seconds for consistent x-axis
            if (!formatted[pondType][date]) {
                formatted[pondType][date] = [];
            }
            formatted[pondType][date].push({
                predicted_E_coli_concentration: entry.predicted_E_coli_concentration,
                actual_E_coli_concentration: entry.actual_E_coli_concentration,
                predicted_Enterococcus_concentration: entry.predicted_Enterococcus_concentration,
                actual_Enterococcus_concentration: entry.actual_Enterococcus_concentration,
            });
        });

        return formatted;
    };

    return (
        <Layout>
            <div>
                <div style={{ paddingLeft: 2 }}>
                    <h5>Showing data from the past 10 minutes</h5>

                        {data ? (
                            <LineGraph 
                                data={data} 
                                componentId="Physical" 
                                showDeleteIcon={false} 
                                handleDelete={() => {}} 
                            />
                        ) : (
                            <p>Loading data for physical parameters...</p>
                        )}

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                            {eColiPredictionData ? (
                                <PredictionLineGraph 
                                    data={eColiPredictionData} 
                                    componentId="Ecoli" 
                                    showDeleteIcon={false} 
                                    handleDelete={() => {}} 
                                />
                            ) : (
                                <p>Loading E coli data...</p>
                            )}

                            {enterococcusPredictionData ? (
                                <PredictionLineGraph 
                                    data={enterococcusPredictionData} 
                                    componentId="Enterococcus" 
                                    showDeleteIcon={false} 
                                    handleDelete={() => {}} 
                                />
                            ) : (
                                <p>Loading Enterococcus data...</p>
                            )}
                        </div>
                    </div>
                </div>
        </Layout>
    );
};

export default HistoricalDataPage;
