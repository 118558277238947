import React from 'react';
import Layout from '../../DashboardUI/LayoutComponent';

const Search: React.FC = () => {
    return (
        <Layout>
            <div style={{ textAlign: 'center', margin:'auto' }}>
                <h1>Search Page</h1>
            </div>
        </Layout>
    );
}

export default Search;