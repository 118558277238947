import React from 'react';
import { Button } from '@mui/material';
import { authClient } from '../APIClient/AuthClient';
import { useNavigate } from 'react-router-dom';

interface LogOutComponentProps {
    onLogoutSuccess: () => void; // Callback function for logout success
    onLogoutFailure: () => void; // Callback function for logout failure
}

const LogOutComponent: React.FC<LogOutComponentProps> = ({ onLogoutSuccess, onLogoutFailure }) => {
    const navigate = useNavigate();

    // Function to handle log out
    const handleLogout = () => {
        authClient.logout().then((res) => {
            if (res.status === 'success') { 
                onLogoutSuccess(); // Call the onLogoutSuccess callback
                setTimeout(() => {
                    navigate('/auth/signin');
                }, 2000); // 2000 milliseconds delay
            } else {
                onLogoutFailure(); // Call the onLogoutFailure callback
            }
        });
    }

    return (
        <Button
            variant="outlined"
            color="error"
            onClick={handleLogout}
            sx={{ margin: '8px' }}
        >
            Logout
        </Button>
    );
}

export default LogOutComponent;
