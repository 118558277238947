import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import Box from '@mui/material/Box';

interface ParameterData {
    temperature: number;
    pH: number;
    salinity: number;
    DO: number;
}

interface SensorData {
    [timestamp: string]: ParameterData;
}

interface PondData {
    G2: SensorData;
    G3: SensorData;
    G4: SensorData;
}

interface LineGraphProps {
    data: PondData;
    parameter: keyof ParameterData;
    title: string;
}

const LineGraph = ({ data, parameter, title }: LineGraphProps) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartInstanceRef = useRef<echarts.EChartsType | null>(null);

    useEffect(() => {
        if (chartContainerRef.current) {
            if (!chartInstanceRef.current) {
                chartInstanceRef.current = echarts.init(chartContainerRef.current);
            }

            const myChart = chartInstanceRef.current;
            const ponds = ['G2', 'G3', 'G4'];

            const seriesData = ponds.map(pond => ({
                name: pond,
                type: 'line',
                data: Object.keys(data[pond as keyof PondData] || {}).map(timestamp => ({
                    name: timestamp,
                    value: [
                        timestamp,
                        data[pond as keyof PondData][timestamp][parameter]
                    ]
                }))
            }));

            const timestamps = Array.from(new Set(ponds.flatMap(pond => Object.keys(data[pond as keyof PondData])))).sort();

            // Determine y-axis label based on the parameter
            let yAxisLabel = '';
            switch (parameter) {
                case 'temperature':
                    yAxisLabel = '°C';
                    break;
                case 'salinity':
                    yAxisLabel = 'ppt';
                    break;
                case 'DO':
                    yAxisLabel = 'mg/L';
                    break;
                case 'pH':
                    yAxisLabel = 'pH'; 
                    break;
                default:
                    yAxisLabel = '';
            }

            const option = {
                title: {
                    text: title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params: any) => {
                        const param = params[0];
                        return `${param.name}<br />${param.seriesName}: ${param.value[1]} ${yAxisLabel}`;
                    }
                },
                legend: {
                    bottom: 10,
                    left: 'center',
                    data: ponds
                },
                xAxis: {
                    type: 'category',
                    data: timestamps,
                    boundaryGap: false
                },
                yAxis: {
                    type: 'value',
                    name: yAxisLabel
                },
                series: seriesData
            };

            myChart.setOption(option);
        }

        // Add passive event listeners
        const addPassiveEventListeners = () => {
            if (chartContainerRef.current) {
                chartContainerRef.current.addEventListener('wheel', (event) => {
                    event.preventDefault();
                }, { passive: true });

                chartContainerRef.current.addEventListener('touchmove', (event) => {
                    event.preventDefault();
                }, { passive: true });
            }
        };

        addPassiveEventListeners();

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.dispose();
                chartInstanceRef.current = null;
            }
        };
    }, [data, parameter, title]);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                bgcolor: 'white',
                width: '100%',
                height: '300px',
                padding: '5px',
                aspectRatio: '2',
            }}
        >
            <div ref={chartContainerRef} style={{ height: '100%', width: '100%' }}></div>
        </Box>
    );
};

export default LineGraph;