import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the DeleteIcon
interface AggregateData {
    [group: string]: GroupData;
}

interface GroupData {
    [item: string]: number;
}

interface BarGraphProps {
    /** Data for the bar graph */
    data: AggregateData;
    componentId: string;
    showDeleteIcon: boolean; // Prop to control delete icon visibility
    handleDelete: (componentId: string) => void; // Function to handle deletion
}

const BarGraph = ({ data, componentId, showDeleteIcon, handleDelete }: BarGraphProps) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);

    let title = '';

    if (componentId.includes('Biology')) {
        title = 'Pathogen Distribution Chart';
    }
    if (componentId.includes('Chemical')) {
        title = 'Chemical Distribution Chart';
    }

    useEffect(() => {
        if (chartContainerRef.current) {
            const myChart = echarts.init(chartContainerRef.current);

            const pondNames = Object.keys(data); // Get the names of all ponds

            const items = Object.keys(data[pondNames[0]]); // Get the items from the first pond

            const option = {
                title: {
                    text: `${title} (${pondNames.join(', ')})`,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}',
                },
                xAxis: {
                    type: 'category',
                    data: pondNames, // Set the x-axis data to pond names
                },
                yAxis: {
                    type: 'value'
                },
                legend: {
                    bottom: 0,
                    left: 'center',
                    data: items, // Use item names as legend data
                },
                series: items.map((item, index) => ({
                    name: item,
                    type: 'bar',
                    data: pondNames.map(pondName => data[pondName][item]), // Data for each pond's item
                }))
            };

            myChart.setOption(option);

            // Clean up function to dispose the chart when component unmounts
            return () => {
                myChart.dispose();
            };
        }
    }, [data]);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                bgcolor: 'white',
                p: 2,
                width: '1620px',
                height: '390px',
            }}
        >
            <div ref={chartContainerRef} style={{ height: 'calc(100% - 40px)', width: '100%' }}></div>
            {/* Render the delete icon conditionally */}
            {showDeleteIcon && (
                <IconButton
                    style={{
                        position: 'absolute',
                        bottom: '4px',
                        right: '4px',
                        color: 'red',
                    }}
                    onClick={() => handleDelete(componentId)}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default BarGraph;