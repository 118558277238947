import React, { useEffect, useState } from 'react';
import Layout from '../../DashboardUI/LayoutComponent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { UserProfileData, userClient } from '../../APIClient/UserClient';
import SnackbarComponent from '../DisplayComponent';

const UserProfile: React.FC = () => {
    const [userProfiles, setUserProfiles] = useState<UserProfileData[]>([]);
    const [fetchUserProfileFailed, setFetchUserProfileFailed] = useState(false);
    const [fetchUserProfileSuccess, setFetchUserProfileSuccess] = useState(false);
    const [deleteUserProfileFailed, setDeleteUserProfileFailed] = useState(false);
    const [deleteUserProfileSuccess, setDeleteUserProfileSuccess] = useState(false);

    useEffect(() => {
        // Fetch user profile data when the component mounts
        const fetchData = async () => {
            const res = await userClient.fetchUserProfile();
            if (res.status === 'success') {
                setUserProfiles(res.data || []);
                setFetchUserProfileSuccess(true);
            } else {
                setFetchUserProfileFailed(true);
            }
        };
        fetchData();
    }, []);

    // Function to remove a user
    const handleRemove = async (id: string) => {
        // Call the API to delete the user
        const res = await userClient.deleteUser(id);
        if (res.status === 'success') {
            // Remove the user from the state
            setUserProfiles(userProfiles.filter(user => user.id !== id));
            setDeleteUserProfileSuccess(true);
        } else {
            setDeleteUserProfileFailed(true);
        }
    };

    return (
        <Layout>
            <div style={{ textAlign: 'center', margin: 'auto' }}>
                <h1> </h1>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Action</TableCell> {/* Add a column for action */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userProfiles.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.first_name}</TableCell>
                                <TableCell>{row.last_name}</TableCell>
                                <TableCell>{row.role}</TableCell> 
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleRemove(row.id)} // Handle remove action
                                    >
                                        Remove
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Snackbar to display fetch user profiles failure */}
            <SnackbarComponent
                message="Refresh Fail"
                open={fetchUserProfileFailed}
                onClose={() => setFetchUserProfileFailed(false)}
                severity="error"
            />
            {/* Snackbar to display fetch user profiles success */}
            <SnackbarComponent
                message="Refresh Success"
                open={fetchUserProfileSuccess}
                onClose={() => setFetchUserProfileSuccess(false)}
                severity="success"
            />
            {/* Snackbar to display delete user profiles failure */}
            <SnackbarComponent
                message="Remove Fail"
                open={deleteUserProfileFailed}
                onClose={() => setDeleteUserProfileFailed(false)}
                severity="error"
            />
            {/* Snackbar to display delete user profiles success */}
            <SnackbarComponent
                message="Remove Success"
                open={deleteUserProfileSuccess}
                onClose={() => setDeleteUserProfileSuccess(false)}
                severity="success"
            />
        </Layout>
    );
}

export default UserProfile;
