import React, { useState, useEffect, useRef } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Layout from '../../DashboardUI/LayoutComponent';
import ForecastLineGraph from './ForecastLineGraph';
import { dataClient } from '../../APIClient/DataClient';
import { ForecastPondData } from './ForecastTypes';
import moment from 'moment';

const Forecast: React.FC = () => {
    const [realTimeData, setRealTimeData] = useState<ForecastPondData>({ G2: {}, G3: {} });
    const [forecastData, setForecastData] = useState<ForecastPondData>({ G2: {}, G3: {} });
    const [fetchError, setFetchError] = useState<string | null>(null);

    const wsRefs = useRef<{ [key: string]: React.MutableRefObject<WebSocket | null> }>({
        G2_Ecoli: useRef<WebSocket | null>(null),
        G3_Ecoli: useRef<WebSocket | null>(null),
        G2_Enterococcus: useRef<WebSocket | null>(null),
        G3_Enterococcus: useRef<WebSocket | null>(null),
    });

    const sensorEndpoints: { [key: string]: string } = {
        G2_Ecoli: `${process.env.REACT_APP_API_URL}/ws/pondg2_ecoli_prediction_endpoint/`,
        G3_Ecoli: `${process.env.REACT_APP_API_URL}/ws/pondg3_ecoli_prediction_endpoint/`,
        G2_Enterococcus: `${process.env.REACT_APP_API_URL}/ws/pondg2_enterococcus_prediction_endpoint/`,
        G3_Enterococcus: `${process.env.REACT_APP_API_URL}/ws/pondg3_enterococcus_prediction_endpoint/`
    };

    useEffect(() => {
        const connectWebSocket = (pond: string, endpoint: string, wsRef: React.MutableRefObject<WebSocket | null>) => {
            const ws = new WebSocket(endpoint);

            ws.onopen = () => {
                console.log(`${pond} WebSocket connection established`);
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    const timestamp = new Date(data.timestamp).toLocaleString();
                    console.log(`Received data for ${pond}:`, data);

                    setForecastData(prevData => {
                        const pondKey = pond.split('_')[0];
                        const updatedPondData = {
                            ...prevData[pondKey],
                            [timestamp]: {
                                ...prevData[pondKey]?.[timestamp],
                                ...data
                            }
                        };
                        return { ...prevData, [pondKey]: updatedPondData };
                    });
                } catch (error) {
                    console.error(`Error parsing ${pond} WebSocket message:`, error);
                }
            };

            ws.onerror = (error) => {
                console.error(`${pond} WebSocket error:`, error);
                setTimeout(() => {
                    connectWebSocket(pond, endpoint, wsRef);
                }, 3000);
            };

            ws.onclose = (event) => {
                console.log(`${pond} WebSocket connection closed`, event);
                setTimeout(() => {
                    connectWebSocket(pond, endpoint, wsRef);
                }, 3000);
            };

            wsRef.current = ws;
        };

        Object.keys(sensorEndpoints).forEach((pond) => {
            connectWebSocket(pond, sensorEndpoints[pond], wsRefs.current[pond]);
        });

        const fetchPredictedPondData = async (pathogenType: string) => {
            try {
                const now = moment.utc();
                const endDate = now.format('DD-MM-YYYY HH:mm');
                const startDate = now.clone().subtract(30, 'minutes').format('DD-MM-YYYY HH:mm');
                const pondType = 'G2'; // Adjust as needed

                const response = await dataClient.fetchForecastData({ pondType, pathogenType, startDate, endDate });

                if (response.status === 'success' && response.data) {
                    setForecastData(response.data);
                    setFetchError(null);
                } else {
                    setFetchError('Failed to fetch predicted pond data from the past 30 minutes.');
                }
            } catch (error) {
                console.error('Error fetching predicted pond data:', error);
                setFetchError('Failed to fetch predicted pond data.');
            }
        };

        fetchPredictedPondData('E_coli');
        const interval = setInterval(() => fetchPredictedPondData('E_coli'), 60000);

        return () => {
            Object.keys(wsRefs.current).forEach((pond) => {
                if (wsRefs.current[pond].current) {
                    wsRefs.current[pond].current!.close();
                }
            });
            clearInterval(interval);
        };
    }, []);

    return (
        <Layout>
            {fetchError && (
                <Box sx={{ mb: 2 }}>
                    <Alert severity="error">{fetchError}</Alert>
                </Box>
            )}
            <ForecastLineGraph
                realTimeData={realTimeData}
                forecastData={forecastData}
                title="E Coli Prediction"
                parameters={['E_coli_concentration', 'actual_E_coli_concentration']}
            />
            <ForecastLineGraph
                realTimeData={realTimeData}
                forecastData={forecastData}
                title="Enterococcus Prediction"
                parameters={['Enterococcus_concentration', 'actual_Enterococcus_concentration']}
            />
        </Layout>
    );
};

export default Forecast;
