import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import Box from '@mui/material/Box';

interface ForecastParameterData {
    E_coli_concentration?: number;
    actual_E_coli_concentration?: number;
    Enterococcus_concentration?: number;
    actual_Enterococcus_concentration?: number;
}

interface ForecastSensorData {
    [timestamp: string]: ForecastParameterData;
}

interface ForecastPondData {
    [key: string]: ForecastSensorData;
    G2: ForecastSensorData;
    G3: ForecastSensorData;
}

interface LineGraphProps {
    realTimeData: ForecastPondData;
    forecastData: ForecastPondData;
    title: string;
    parameters: (keyof ForecastParameterData)[];
}

const ForecastLineGraph: React.FC<LineGraphProps> = ({ realTimeData, forecastData, title, parameters }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartInstanceRef = useRef<echarts.EChartsType | null>(null);

    useEffect(() => {
        if (chartContainerRef.current) {
            if (!chartInstanceRef.current) {
                chartInstanceRef.current = echarts.init(chartContainerRef.current);
            }

            const myChart = chartInstanceRef.current;
            const ponds: (keyof ForecastPondData)[] = ['G2', 'G3'];

            const seriesData = parameters.flatMap(parameter =>
                ponds.flatMap(pond => [
                    {
                        name: `${pond} ${parameter.replace(/_/g, ' ')}`,
                        type: 'line',
                        data: Object.keys(realTimeData[pond] || {})
                            .map(timestamp => ({
                                name: timestamp,
                                value: [
                                    timestamp,
                                    realTimeData[pond][timestamp][parameter] as number 
                                ]
                            }))
                    },
                    {
                        name: `${pond} ${parameter.replace(/_/g, ' ')}`,
                        type: 'line',
                        data: Object.keys(forecastData[pond] || {})
                            .map(timestamp => ({
                                name: timestamp,
                                value: [
                                    timestamp,
                                    forecastData[pond][timestamp][parameter] as number 
                                ]
                            }))
                    }
                ])
            );

            const timestamps = Array.from(new Set([
                ...ponds.flatMap(pond => Object.keys(realTimeData[pond])),
                ...ponds.flatMap(pond => Object.keys(forecastData[pond]))
            ])).sort();

            const option = {
                title: {
                    text: title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params: any) => {
                        return params.map((param: any) => `${param.seriesName}: ${param.value[1]}`).join('<br />');
                    }
                },
                legend: {
                    bottom: 10,
                    left: 'center',
                    data: seriesData.map(series => series.name)
                },
                grid: {
                    top: 50, 
                    bottom: 50 
                },
                xAxis: {
                    type: 'category',
                    data: timestamps,
                    boundaryGap: false
                },
                yAxis: {
                    type: 'value',
                    name: 'log₁₀MPN/100mL'
                },
                series: seriesData
            };

            myChart.setOption(option);
        }

        // Add passive event listeners
        const addPassiveEventListeners = () => {
            if (chartContainerRef.current) {
                chartContainerRef.current.addEventListener('wheel', (event) => {
                    event.preventDefault();
                }, { passive: true });

                chartContainerRef.current.addEventListener('touchmove', (event) => {
                    event.preventDefault();
                }, { passive: true });
            }
        };

        addPassiveEventListeners();

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.dispose();
                chartInstanceRef.current = null;
            }
        };
    }, [realTimeData, forecastData, parameters, title]);

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                bgcolor: 'white',
                width: '80%',
                height: '30%',
                padding: '10px',
                aspectRatio: '2',
            }}
        >
            <div ref={chartContainerRef} style={{ height: '100%', width: '100%' }}></div>
        </Box>
    );
};

export default ForecastLineGraph;
