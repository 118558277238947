import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface SnackbarComponentProps {
    open: boolean;
    onClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
    message: string;
    severity: 'success' | 'error' | 'info' | 'warning';
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({ open, onClose, message, severity }) => {
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose(event, reason || ''); // Ensuring reason is never undefined
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarComponent;
