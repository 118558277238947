// Sign Up component

import { Box, Button, Link, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { SignUpParams, authClient } from '../APIClient/AuthClient'
import { useState } from 'react'
import SnackbarComponent from '../DashboardUI/DisplayComponent';


export default function SignupForm() {
  const navigate = useNavigate() //init navigate
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [signUpFailed, setSignUpFailed] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  // Event handlers to update state based on user input
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value);
  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value);
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  // Function to handle signup
    const handleSignup = () => {
        let param: SignUpParams = {
            email: email, first_name: firstName, last_name: lastName, password: password
            // Default Components for first sign up
            /*componentId: "Biology" + "_" + Math.random().toString(36).substring(7), parameters: {
                'Chart': 'Bar Graph',
                'Pond': ['G2', 'G3', 'G4', 'G5', 'G7', 'G8', 'G9', 'G10']
            },
            componentId2: "Chemical" + "_" + Math.random().toString(36).substring(7), parameters2: {
                'Chart': 'Pie Chart',
                'Pond': ['G2', 'G3', 'G4', 'G5', 'G7', 'G8', 'G9', 'G10']
            },
            componentId3: "Physical" + "_" + Math.random().toString(36).substring(7), parameters3: {
                'Chart': 'Line Graph',
                'Pond': 'G4',
                'StartDate': '01-03-2022',
                'EndDate': '31-03-2022'
            }*/
        }
        authClient.signUp(param).then((res) => {
            if (res.status === 'success') {  
                setSignUpSuccess(true); 
                setTimeout(() => {
                    navigate('/auth/signin');
                }, 2000); // 2000 milliseconds delay
            } else {
                setSignUpFailed(true); 
            } 
        })
    };

    return (
        <Box    //sign up form container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '& div': { display: 'flex' },
                '& .MuiTextField-root': { m: 1, flexGrow: 1 },
                '& Button': { m: 1 },
                '& p': { m: 1 },
                '& h2': { m: 1 },
            }}
        >
            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                Sign up for AquaCulture
            </Typography>
            <div>
                <TextField
                    required
                    fullWidth
                    id="email-input"
                    label="Email"
                    autoComplete="email"
                    onChange={handleEmailChange}
                >
                    {email}
                </TextField>
            </div>
            <div>
                <TextField
                    id="firstName-input"
                    label="First Name"
                    autoComplete="given-name"
                    onChange={handleFirstNameChange}
                >
                    {firstName}
                </TextField>
                <TextField
                    id="lastName-input"
                    label="Last Name"
                    autoComplete="family-name"
                    onChange={handleLastNameChange}
                >
                    {lastName}
                </TextField>
            </div>
            <div>
                <TextField
                    required
                    id="password-input"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={handlePasswordChange}
                >
                    {password}
                </TextField>
            </div>
            <Button variant="contained" onClick={handleSignup}>Submit</Button>
            <Typography color="text.secondary" variant="body2">
                Already have an account?{' '}
                <Link href={'/auth/signin'} underline="hover" variant="subtitle2">
                    Sign in
                </Link>
            </Typography>
            {/* Snackbar to display sign up failure */}
            <SnackbarComponent
                message="Sign up Fail"
                open={signUpFailed}
                onClose={() => setSignUpFailed(false)}
                severity="error"
            />
            {/* Snackbar to display sign up success */}
            <SnackbarComponent
                message="Sign up Success"
                open={signUpSuccess}
                onClose={() => setSignUpSuccess(false)}
                severity="success"
            />
        </Box>
    );

}